<template>
  <v-app>
    <loading :active.sync="is_call_api"></loading>
    <div style="background-color: rgb(243 246 249)">
      <h1 class="mt-5 mb-5" style="color: #3f4254">
        Danh sách Conversation Tag
      </h1>
    </div>
    <div class="pt-5 pl-5 pr-5">
      <v-btn
        rounded
        color="primary"
        class="mb-5"
        x-small
        large
        @click="openDiaLogTag"
        v-if="canAccess('cs-create')"
      >
        <v-icon color="white" dark>mdi-plus</v-icon
        ><span style="color: white">Thêm Conversation Tag</span>
      </v-btn>
      <div>
        <v-row>
          <v-col cols="6">
            <v-pagination
              class="mt-5"
              color="#00BCD4"
              style="float: left"
              v-model="data_pagination.current_page"
              :length="data_pagination.total_pages"
              :total-visible="6"
            ></v-pagination>
          </v-col>
          <v-col cols="6">
            <b-form-input
              class="mt-6"
              style="float: right; width: 300px"
              placeholder="Search"
              v-model="search_input"
            ></b-form-input>
          </v-col>
          <v-col cols="12">
            <div class="table-responsive">
              <table
                class="table datatable-bordered table-head-custom table-vertical-center table-head-bg"
              >
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Name</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <template v-for="(tag_type, i) in tag_types">
                    <tr :key="i">
                      <td scope="row">{{ i + 1 }}</td>
                      <td>
                        <p
                          class="text-dark-75 font-weight-bolder d-block font-size-lg"
                        >
                          {{ tag_type.name }}
                        </p>
                      </td>
                      <td>
                        <div style="display: flex">
                          <v-tooltip left>
                            <template v-slot:activator="{ on, attrs }">
                              <button
                                class="btn btn-icon btn-light-success btn-sm"
                                v-bind="attrs"
                                v-on="on"
                                @click="editTagType(i)"
                                v-if="canAccess('cs-update')"
                              >
                                <span
                                  class="svg-icon svg-icon-md svg-icon-primary"
                                >
                                  <v-icon color=""
                                    >mdi-square-edit-outline</v-icon
                                  >
                                </span>
                              </button>
                            </template>
                            <span>Edit</span>
                          </v-tooltip>
                          <v-tooltip right>
                            <template v-slot:activator="{ on, attrs }">
                              <button
                                class="btn btn-icon btn-light-danger btn-sm ml-4"
                                v-bind="attrs"
                                v-on="on"
                                @click="btnDeleteTagType(tag_type.id)"
                                v-if="canAccess('cs-delete')"
                              >
                                <span
                                  class="svg-icon svg-icon-md svg-icon-primary"
                                >
                                  <v-icon color="">mdi-delete</v-icon>
                                </span>
                              </button>
                            </template>
                            <span>Delete</span>
                          </v-tooltip>
                        </div>
                      </td>
                    </tr>
                  </template>
                </tbody>
              </table>
            </div>
          </v-col>
          <v-col cols="6">
            <v-pagination
              style="float: left"
              color="#00BCD4"
              v-model="data_pagination.current_page"
              :length="data_pagination.total_pages"
              :total-visible="6"
            ></v-pagination>
          </v-col>
        </v-row>
      </div>
    </div>

    <v-row justify="center">
      <v-dialog
        v-model="dialogTagType"
        max-width="1000px"
        scrollable
        persistent
      >
        <v-card>
          <v-card-title>
            <v-col cols="11">
              <span v-if="type_form_tag_type == 'add'" class="headline"
                >Add Tag</span
              >
              <span v-if="type_form_tag_type == 'update'" class="headline"
                >Edit Tag</span
              >
            </v-col>
            <v-col cols="1">
              <v-icon @click="dialogTagType = false" right>mdi-close</v-icon>
            </v-col>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="6" class="pt-0">
                  <v-text-field
                    label="Name"
                    v-model="name_input"
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <button
              class="btn btn-light-success text-uppercase mr-2"
              v-if="type_form_tag_type == 'add'"
              @click="btnAddOrEditTagType(1)"
            >
              Add
            </button>
            <button
              class="btn btn-light-success text-uppercase mr-2"
              v-if="type_form_tag_type == 'update'"
              @click="btnAddOrEditTagType(2)"
            >
              Update
            </button>
            <button
              class="btn text-uppercase mr-2"
              style="color: #f64e60"
              @click="dialogTagType = false"
            >
              Close
            </button>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </v-app>
</template>

<script>
import "vue-loading-overlay/dist/vue-loading.css";
import ApiService from "@/service/api.service";
import Swal from "sweetalert2";
export default {
  name: "ConversationTagList",
  components: {
    Loading: () => import("vue-loading-overlay"),
  },
  data() {
    return {
      data_pagination: {
        current_page: 1,
        total_pages: 1,
      },
      tag_types: [],
      search_input: "",
      is_call_api: false,
      dialogTagType: false,
      type_form_tag_type: null,
      name_input: "",
      tag_type_id: null,
    };
  },
  created() {
    this.getAllTagType();
  },
  methods: {
    canAccess(permission) {
      return this.$utils.canAccess(permission);
    },
    async getAllTagType() {
      let vm = this;
      try {
        vm.is_call_api = true;
        let url = "prep-app/conversation-tag";
        if (vm.search_input) {
          url = "prep-app/conversation-tag?name=" + vm.search_input;
        }

        let res = await ApiService.get(url);

        if (res.status == 200) {
          vm.tag_types = res.data.data;
          vm.data_pagination = res.data.meta.pagination;
          vm.is_call_api = false;
        }
      } catch (error) {
        vm.is_call_api = false;
        console.log(error);
      }
    },
    openDiaLogTag() {
      this.name_input = "";
      this.type_form_tag_type = "add";
      this.dialogTagType = true;
    },
    editTagType(index) {
      let data = this.tag_types[index];
      this.name_input = data.name;
      this.type_form_tag_type = "update";
      this.tag_type_id = data.id;
      this.dialogTagType = true;
    },
    btnDeleteTagType(tag_type_id) {
      let vm = this;
      Swal.fire({
        title: "Are you sure you want to delete?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.value) {
          ApiService.delete("prep-app/conversation-tag/" + tag_type_id)
            .then(function (res) {
              if (res.status == 202) {
                vm.$toasted.success("Delete successfully!!", {
                  theme: "toasted-primary",
                  position: "top-right",
                  duration: 4000,
                });
                vm.getAllTagType(vm.data_pagination.current_page);
              }
            })
            .catch(function (error) {
              console.log(error);
            });
        }
      });
    },
    async btnAddOrEditTagType(type) {
      let vm = this;
      let data = {
        name: this.name_input,
      };
      if (type == 1) {
        vm.is_call_api = true;
        try {
          let res = await ApiService.post("prep-app/conversation-tag", data);
          if (res.status == 200) {
            vm.getAllTagType();
            Swal.fire({
              title: "",
              text: "The application has been successfully submitted!",
              icon: "success",
              confirmButtonClass: "btn btn-secondary",
            });
            vm.dialogTagType = false;
            vm.is_call_api = false;
          }
        } catch (error) {
          vm.is_call_api = false;
          if (error.response.status == 422) {
            let data_response_error =
              error.response.data.error.message.split(".<br />");
            vm.showError(data_response_error);
          }
        }
      } else {
        try {
          let res = await ApiService.put(
            "prep-app/conversation-tag/" + vm.tag_type_id,
            data
          );
          if (res.status == 202) {
            vm.getAllTagType(vm.data_pagination.current_page);
            Swal.fire({
              title: "",
              text: "The application has been successfully submitted!",
              icon: "success",
              confirmButtonClass: "btn btn-secondary",
            });
            vm.dialogTagType = false;
            vm.is_call_api = false;
          }
        } catch (error) {
          vm.is_call_api = false;
          if (error.response.status == 422) {
            let data_response_error =
              error.response.data.error.message.split(".<br />");
            vm.showError(data_response_error);
          }
        }
      }
    },
    showError(data_error) {
      for (let i = 0; i < data_error.length; i++) {
        this.$toasted.error(data_error[i] + " !!", {
          theme: "toasted-primary",
          position: "top-right",
          duration: 4000,
        });
      }
    },
  },
  watch: {
    // whenever question changes, this function will run
    search_input: function () {
      this.getAllTagType();
    },
  },
};
</script>

<style lang="css" scoped>
.table.table-head-bg thead th {
  background-color: #2196f3eb !important;
  color: white !important;
}
</style>
